/* @font-face {
  font-family: "WorkSans-Regular";
  src: local("WorkSans-Regular"), url(../src/assets/font/Work_Sans/static/WorkSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "worksans-Light";
  src: local("WorkSans-Light"), url(../src/assets/font/Work_Sans/static/WorkSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Worksans-Bold";
  src: local("WorkSans-Bold"), url(../src/assets/font/Work_Sans/static/WorkSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "WorkSans-SemiBold";
  src: local("WorkSans-SemiBold"), url(../src/assets/font/Work_Sans/static/WorkSans-SemiBold.ttf) format("truetype");
}

body {
  margin: 0;
    font-family: "WorkSans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



.inport_modal {
  width: 100% !important;
  max-width: 280px !important;
  border-radius: 12px !important;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.inport_modal .search_form label {
  font-size: 17px !important;
  font-weight: 600;
  display: flex;
  gap: 7px;
}.date_range_ui  h3 {
  font-weight: 500;
  color: #000000d9;
  font-style: normal;
  line-height: 19px;
  font-size: 18px;
  margin: 0;
}

.ant-modal.add_participend {
  width: 100% !important;
  max-width: 650px;
}